import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";

import "../css/home.css";
import "../css/default.css";

import Navbar from "../components/navbar/navbar.js";

const quotes = [
    "Madness is rare in individuals—but in groups, parties, nations, and ages it is the rule.",
    "What important truth do very few people agree with you on?",
];

function Home({ location }) {
    let quoteIndex = 0;

    let [activeQuote, setActiveQuote] = useState(
        "Madness is rare in individuals—but in groups, parties, nations, and ages it is the rule."
    );

    let [quoteOverlayClass, setQuoteOverlayClass] = useState("quoteOverlay");

    const nextQuote = useCallback(() => {
        if (quoteIndex === quotes.length - 1) {
            quoteIndex = 0;
            setActiveQuote(quotes[quoteIndex]);
        } else {
            quoteIndex++;
            setActiveQuote(quotes[quoteIndex]);
        }
    });

    // Start interval on component mount
    useEffect(() => {
        const interval = setInterval(() => {
            console.log("interval running");
            // do animation and then switch qoute.

            setQuoteOverlayClass("quoteOverlay active");
            // wait 0.5s for transition
            setTimeout(() => {
                nextQuote();
            }, 1000);

            setTimeout(() => {
                setQuoteOverlayClass("quoteOverlay");
            }, 1500);
        }, 15 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <title>Home | Contrarian Capital</title>
            </Helmet>
            <Navbar location={location} />
            <div id="homeWrapper">
                <div className="logo-container">
                    <img src="/contrarian-capital-logo.svg" alt="" />
                </div>

                <div className="quote-container">
                    <div className="quote-container-content">
                        <img src="/quote-icon.svg" alt=""></img>

                        <p>{activeQuote}</p>

                        <div className={quoteOverlayClass}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
